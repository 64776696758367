
import { LoginRequest } from "@/models/entities/login-request.interface";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import { authenticate } from "@/services/api/authenticate.service";
import { required, sameValue } from "@/helpers/rules";
import { resetPasswordService } from "@/services/api/reset-password.service";
import { SnackbarModule, ZTabItem } from "vuelando";
// import { loadLanguageAsync } from "@/i18n/config";
// import LanguageModule from "@/store/modules/language.module";
import { UserSignUp } from "@/models/entities/user-signup.interface";
import { signUpService } from "@/services/api/sign-up.service";
import { PasswordChangeReset } from "@/models/entities/password-change-reset.interface";

// const languageModule = getModule(LanguageModule, store);

const authModule = getModule(AuthModule, store);

const overlayModule = getModule(OverlayModule, store);

const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class Home extends Vue {
  @Prop({ type: String, default: "", required: false })
  private next: string;
  //   private language: string = null;
  private valid = true;
  private showPassword = false;
  private required = required;
  private errorBlocked = false;
  private forgotPassword = false;
  private email = "";
  private selected = 0;
  private isPasswordValid = "";
  private signUpCompleted = false;
  private emailSent = false;
  private resetPasswordByLink = false;
  private newPassword = "";
  private confirmNewPassword = "";
  private validSignUp = true;
  private sameValue = sameValue;
  private passwordChangeReset: PasswordChangeReset = {
    token: "",
    password: "",
  };

  private get tabItems(): ZTabItem[] {
    return [
      {
        text: this.$tc("login.Login"),
        value: null,
      },
      {
        text: this.$tc("login.SignUp"),
        value: null,
      },
    ];
  }
  private userDetails: UserSignUp = {
    userDescription: "",
    password: "",
    email: "",
  };

  //   private items = [
  //     {
  //       label: "Italiano",
  //       action: async () => {
  //         await this.changeLanguage("languages.it");
  //         this.selected = 0;
  //       },
  //     },
  //     {
  //       label: "English",
  //       action: async () => {
  //         await this.changeLanguage("languages.en");
  //         this.selected = 0;
  //       },
  //     },
  //   ];

  private loginRequest: LoginRequest = {
    email: "",
    password: "",
    rememberMe: false,
  };

  async mounted(): Promise<void> {
    window.addEventListener("popstate", this.showForgotPasswordClose);
    this.loginRequest.email = "";
    this.loginRequest.password = "";
    //  this.language = `languages.${this.$i18n.locale}`;
    let token = this.$route.query.token;
    let registration = this.$route.query.registration;
    if (token != null) {
      this.passwordChangeReset.token = token.toString();
      this.resetPasswordByLink = true;
    }
    if (registration != null) {
      this.selected = 1;
    }
  }

  private async login(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      overlayModule.showOverlay();
      try {
        let r = await authenticate.login(this.loginRequest);
        authModule.login(r.data);
        this.$router.push({ name: "home" });
      } catch (e) {
        // this.errorBlocked = e.response.status == 403;
        if (e.response.status == 400) {
          snackbarModule.showSnackbar({
            message: this.$tc("login.FailedLoginEmail"),
            type: "error",
            timer: 5000,
          });
        } else if (e.response.status == 403)
          snackbarModule.showSnackbar({
            message: this.$tc("login.ConfirmYourAccount"),
            type: "error",
            timer: 5000,
          });
        else {
          snackbarModule.showSnackbar({
            message: this.$tc("login.FailedLoginPassword"),
            type: "error",
            timer: 5000,
          });
        }
      }
    }
  }

  private showForgotPassword(): void {
    this.forgotPassword = true;
  }

  private showForgotPasswordClose(): void {
    this.forgotPassword = false;
  }

  private loginPressBtn(key: any): void {
    if (key.key == "Enter") {
      this.login();
    }
  }

  private async sendEmail(): Promise<void> {
    overlayModule.showOverlay();
    try {
      await resetPasswordService.ResetPassword(this.email);
      this.emailSent = true;
    } catch {
      snackbarModule.showSnackbar({
        message: this.$tc("login.EmailNotFound"),
        type: "error",
        timer: 5000,
      });
    }
  }

  //   private async changeLanguage(language: string): Promise<void> {
  //     this.language = language;
  //     language = language.split(".")[1];
  //     await loadLanguageAsync(language);
  //     languageModule.setLanguage(language);
  //   }

  private async submitSignUp(key: KeyboardEvent) {
    if (key.key == null || key.key == "Enter") {
      if (this.userDetails.password == this.isPasswordValid) {
        try {
          overlayModule.showOverlay();
          await signUpService.SignUp(this.userDetails);
          this.signUpCompleted = true;
          snackbarModule.showSnackbar({
            message: this.$tc("login.ConfirmSignUp"),
            type: "success",
            timer: 5000,
          });
        } catch (e) {
          snackbarModule.showSnackbar({
            message: this.$tc("login.MailDuplicated"),
            type: "error",
            timer: 5000,
          });
        }
      } else {
        snackbarModule.showSnackbar({
          message: this.$tc("login.ErrorPassword"),
          type: "error",
          timer: 5000,
        });
      }
    }
  }

  private async resendEmail() {
    overlayModule.showOverlay();
    await signUpService.SendConfirmMail(this.userDetails.email);
  }

  private openMailClient(): void {
    window.open(
      "mailto: supporto@zelando.it?subject=" + this.$tc("login.ContactUsMail")
    );
  }

  private async saveNewPassword(): Promise<void> {
    if ((this.newPassword = this.confirmNewPassword)) {
      overlayModule.showOverlay();
      this.passwordChangeReset.password = this.confirmNewPassword;
      await resetPasswordService.ChangePasswordReset(this.passwordChangeReset);
      this.resetPasswordByLink = false;
    }
  }
}
