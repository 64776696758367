import { PasswordChangeReset } from "@/models/entities/password-change-reset.interface";
import { ApiService } from "vuelando";

const controller = "resetpassword";

class ResetPasswordService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  ResetPassword(userEmail: string) {
    return this.getAll<void>(`${controller}/${userEmail}`);
  }

  ChangePasswordReset(passwordChangeReset: PasswordChangeReset) {
    return this.post<PasswordChangeReset, void>(
      `${controller}/ChangePassword`,
      passwordChangeReset
    );
  }
}

export const resetPasswordService = new ResetPasswordService();
