import { i18n } from "@/i18n/config";

export const required = (v: string): string | boolean =>
  !!v || i18n.tc("general.RequiredField");
export const email = (v: string): string | boolean =>
  !v ||
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
  i18n.tc("general.EmailMustBeValid");

export const generalFiscalCode = (v: string): string | boolean =>
  !v ||
  /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(v) ||
  i18n.tc("general.FiscalCodeMustBeValid");
export const iban = (v: string): string | boolean =>
  !v ||
  /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/gim.test(
    v
  ) ||
  i18n.tc("general.InvalidIBAN");
export const sameValue = (
  v: string,
  vSame: string,
  errorMessage: string
): string | boolean => !v || !vSame || v === vSame || errorMessage;

export const generalAlert = (
  v: string,
  vShow: boolean,
  errorMessage: string
): string | boolean => !v || vShow === true || errorMessage;
